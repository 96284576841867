import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./Games.css";

const Games = ({ title }) => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [products, setProducts] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAllProducts = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/product/get-all-products");
      if (res.data.success) {
        setProducts(res.data.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <div className="games-container">
      <h1 className="text-center">Popular Games</h1>
      <div className="game-product">
        {products?.map((item, index) => {
          return (
            <div
              key={index}
              className="game"
              onClick={() => navigate(`/product/${item?.name}`)}
            >
              <img src={`${item?.images[0]}`} alt="" />
              <h5>{item?.name}</h5>
              {/* <h6>
                <small>Starting Price: {item?.cost[0]?.price}</small>
              </h6> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Games;
