import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../Footer/Footer.css";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useSelector } from "react-redux";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import LocalMallIcon from "@mui/icons-material/LocalMall";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useSelector((state) => state.user);
  return (
    <React.Fragment>
      <div className="container-fluid footer-container">
        <div className="wa-icon-cont">
          <Link target="_blank" to="https://wa.me/917628019102">
            <WhatsAppIcon className="icon" />
          </Link>
        </div>
        {location.pathname !== "/cart" && (
          <div className="cart-icon-cont wa-icon-cont d-block d-lg-none">
            <LocalMallIcon onClick={() => navigate("/cart")} className="icon" />
          </div>
        )}
        <div className="row">
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <div className="footer-logo">
              <b>
                UNCHARTED<span className="ffca00">STORE</span>
              </b>
            </div>
            <span>
              <small>
                Uncharted Store stands out as the premier destination for
                digital gaming goods and a variety of services. Our commitment
                to excellence ensures unmatched quality and seamless service
                round the clock, 365 days a year.
              </small>
            </span>
            <br />
            <br />
            <span>
              <b>Support</b>
            </span>
            <br />
            <span>+91 76280 19102</span>
            <br />
            {/* <span>Manipur , Bishnupur District, 795134</span> */}
          </div>
          <div className="px-lg-5 quick-links col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>Quick Links</h6>
            <hr />
            <ul>
              {!user && (
                <>
                  <li>
                    <Link to="/login">Login</Link>
                  </li>
                  <li>
                    <Link to="/register">Register</Link>
                  </li>
                </>
              )}
              {/* <li>
                <Link to="/about">About us</Link>
              </li> */}
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <Link to="/track-order">Track Order</Link>
              </li>
            </ul>
          </div>
          <div className="col-6 col-sm-6 col-md-3 col-lg-3 mb-4">
            <h6>Important Pages</h6>
            <hr />
            <ul>
              {/* <li>
                <Link to="/faq">Frequently Asked Questions</Link>
              </li> */}
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/terms">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/refund-policy">Refund Policy</Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 mb-4">
            <h6>Connect here</h6>
            <hr />
            <div className="social-media-links">
              <Link
                target="_blank"
                to="https://www.instagram.com/ronel_soro_?igsh=M3c3ZWppcWkwN3Zs"
              >
                <InstagramIcon className="icon" />
              </Link>
              <Link
                target="_blank"
                to="https://www.facebook.com/share/ywsyyfK4ogbXA466/?mibextid=qi2Omg"
              >
                <FacebookSharpIcon className="icon" />
              </Link>
              <Link target="_blank" to="https://wa.me/917628019102">
                <WhatsAppIcon className="icon" />
              </Link>
              <Link target="_blank" to="mailto:unchatedstore@gmail.com">
                <EmailIcon className="icon" />
              </Link>
            </div>
          </div>
          <hr />
          <span className="text-center">
            <small>
              All Rights Reserved © 2023 | UNCHARTED STORE |{" "}
              <br className="d-block d-md-none d-lg-none" /> Designed &
              Developed by{" "}
              <Link
                style={{ color: "#ebef29", fontWeight: "500" }}
                target="_blank"
                to="https://aashirdigital.com"
              >
                ~aashirdigital
              </Link>
            </small>
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
