const IMAGES = {
  // LOGO
  //HOME
  b1: require("../img/home/banner1.jpg"),
  b2: require("../img/home/banner2.jpeg"),
  b3: require("../img/home/banner3.jpeg"),
  /// PHONE SKIN
  pbanner: require("../img/products/banner2.webp"),
  // barcode
  barcode: require("../img/barcode.jpg"),
  // HOME
  trusted: require("../img/home/trusted.svg"),
  register: require("./home/register.svg"),
  verified: require("./home/verified.svg"),
  privacy: require("./home/privacy.svg"),
};

export default IMAGES;
