import React, { useEffect, useState } from "react";
import AdminLayout from "./components/AdminLayout";
import "./AdminQueries.css";
import axios from "axios";
import { message } from "antd";

const AdminPayments = () => {
  const [payments, setPayments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  const getAllPayments = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/payment/get-all-payments", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      if (res.data.success) {
        setPayments(res.data.data.reverse());
        setLoading(false);
      } else {
        setLoading(false);
        message.error(res.data.message);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllPayments();
  }, []);

  return (
    <AdminLayout>
      <div className="page-title">
        <h3 className="m-0">Payments</h3>
      </div>
      <hr />
      <div className="admin-queries">
        <div className="tools">
          <div className="form-fields">
            <input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="email"
              className="py-2"
              placeholder="Email"
            />
          </div>
        </div>
        <table className="table query-table">
          <thead>
            <tr>
              <th>Txn ID</th>
              <th>Name</th>
              <th>Email</th>
              <th>Amount</th>
              <th>Date</th>
            </tr>
          </thead>
          {loading ? (
            <div className="loader-container text-center">
              <span class="loader"></span>
            </div>
          ) : (
            <tbody>
              {payments &&
                payments
                  ?.filter((item) =>
                    item?.email.toLowerCase().includes(email.toLowerCase())
                  )
                  .map((item, index) => {
                    return (
                      <tr>
                        <td>{item?.client_txn_id}</td>
                        <td>{item?.name}</td>
                        <td>{item?.email}</td>
                        <td>{item?.amount}</td>
                        <td>
                          {new Date(item?.payDate).toLocaleString("default", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })}
                        </td>
                        <td></td>
                      </tr>
                    );
                  })}
            </tbody>
          )}
        </table>
      </div>
    </AdminLayout>
  );
};

export default AdminPayments;
