import React, { useEffect, useState } from "react";
import Layout from "../components/Layout/Layout";
import "./ProductInfo.css";
import IMAGES from "../img/image";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import HowItWorks from "../components/Home/HowItWorks";
import SubscriberForm from "../components/Home/SubscriberForm";
import axios from "axios";
import { message } from "antd";
import { useSelector } from "react-redux";

const ProductInfo = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [playerCheck, setPlayerCheck] = useState(null);
  const [product, setProduct] = useState(0);
  const [showImage, setShowImage] = useState(0);
  const [error, setError] = useState(false);
  const [cart, setCart] = useState(
    JSON.parse(localStorage.getItem("cart")) || []
  );
  const [goToCart, setGoToCart] = useState(false);

  //!NEW STATE
  const [playerId, setPlayerId] = useState("");
  const [amount, setAmount] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [productId, setProductId] = useState("");
  //! API BASED
  const [userId, setUserId] = useState("");
  const [zoneId, setZoneId] = useState("");

  function setPriceAndId(amount) {
    const price = product?.cost?.find((item) => item.amount === amount)?.price;
    setSelectedPrice(price);

    const id = product?.cost?.find((item) => item.amount === amount)?.id;
    setProductId(id);
  }

  const getProduct = async () => {
    try {
      const res = await axios.post("/api/product/get-product-by-name", {
        name: params.name,
      });
      if (res.data.success) {
        setProduct(res.data.data);
        // Set default values from the first item in the cost array
        const defaultAmount = res.data.data?.cost?.[0]?.amount;
        const defaultPrice = res.data.data?.cost?.[0]?.price;
        const defaultId = res.data.data?.cost?.[0]?.id;
        setAmount(defaultAmount);
        setSelectedPrice(defaultPrice);
        setProductId(defaultId);
      } else {
        message.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGoToCart = () => {
    if (cart.length > 0) {
      setGoToCart(true);
    }
  };

  useEffect(() => {
    handleGoToCart();
  }, [cart, product]);

  const handleAddToCart = () => {
    if (product?.api === "yes") {
      if (!playerCheck) {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 1500);
        return;
      }
      if (product === "") {
        return alert("Please Select Product");
      }
      if (productId === "") {
        return alert("Please Select Product");
      }
      if (userId === "") {
        return alert("Please Enter UserId");
      }
      if (zoneId === "") {
        return alert("Please Enter ZoneId");
      }
    } else {
      if (playerId === "") {
        return alert("Please Enter PlayerId");
      }
    }

    const itemToAdd = {
      id: product?._id,
      productId: productId,
      region: product?.region,
      api: product?.api,
      name: product?.name,
      price: selectedPrice,
      images: product?.images,
      playerId: playerId,
      userId: userId,
      zoneId: zoneId,
      amount: amount,
      username: playerCheck,
    };
    // Check if the product is already in the cart
    const existingProductIndex = cart.findIndex(
      (item) =>
        item.id === itemToAdd.id &&
        item.selectedBrand === itemToAdd.selectedBrand &&
        item.selectedModel === itemToAdd.selectedModel
    );

    if (existingProductIndex !== -1) {
      // If the product already exists, update the quantity
      setCart((prevCart) => {
        const updatedCart = [...prevCart];
        localStorage.setItem("cart", JSON.stringify(updatedCart));
        return updatedCart;
      });
    } else {
      // If the product doesn't exist, add it to the cart
      setCart((prevCart) => {
        const updatedCart = [...prevCart, itemToAdd];
        // Store the updated cart in localStorage
        localStorage.setItem("cart", JSON.stringify(updatedCart));
        return updatedCart;
      });
    }
    alert("Item added to cart!");
  };

  useEffect(() => {
    getProduct();
  }, []);

  async function handleCheckPlayer() {
    if (userId === "" || zoneId === "") {
      return message.error(
        `${userId === "" ? "Enter user id" : "Enter zone id"}`
      );
    }
    try {
      setLoading(true);
      const idPro = productId.split("&")[0];
      const object = {
        region: product?.region,
        userid: userId,
        zoneid: zoneId,
        productid: idPro,
      };
      const res = await axios.post("/api/payment/get-role", object);
      if (res.data.success) {
        setPlayerCheck(res.data.username);
        setLoading(false);
      } else {
        message.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <Layout>
      <div className="product-info-container">
        <div className="d-none d-md-none d-lg-block product-info-img-slider">
          {product?.images?.map((item, index) => {
            console.log(item);
            return (
              <img
                onClick={() => setShowImage(index)}
                key={index}
                src={`https://unchartedstore.in/${item}`}
                alt=""
              />
            );
          })}
        </div>
        <div className="product-info-img">
          {product?.images && product?.images.length > 0 && (
            <img
              src={`https://unchartedstore.in/${product?.images[showImage]}`}
              alt=""
            />
          )}
        </div>
        <div className="d-block d-lg-none product-info-img-slider">
          {product?.images?.map((item, index) => {
            return (
              <img
                key={index}
                onClick={() => setShowImage(index)}
                src={`https://unchartedstore.in/${item}`}
                alt="loading.."
              />
            );
          })}
        </div>
        <div className="product-info-content mb-2 text-white">
          <h3>{product?.name}</h3>
          <span className="">Instant Delivery Supported ⚡</span>
          <span>{product?.desc}</span>
          <div className="price ">
            {selectedPrice !== null ? (
              <h3 className="m-0 mt-3">
                <b>Rs. {selectedPrice}</b>
              </h3>
            ) : (
              "Select an amount to see the price"
            )}
          </div>
          <div className="p-amount">
            {product?.cost?.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    setAmount(item.amount);
                    setPriceAndId(item.amount);
                  }}
                  key={index}
                  className={`amount ${amount === item?.amount && "active"}`}
                >
                  <span>
                    <small>{item.amount}</small>
                  </span>
                </div>
              );
            })}
          </div>
          {product?.api === "yes" ? (
            <>
              <div className="d-flex  align-items-center">
                <input
                  className="player-tag"
                  type="text"
                  name="userId"
                  placeholder="User ID"
                  onChange={(e) => setUserId(e.target.value)}
                  value={userId}
                />
                <button className="p-check-btn" onClick={handleCheckPlayer}>
                  Check{" "}
                  {loading && (
                    <div class="spinner-grow spinner-grow-sm" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
              <input
                className="player-tag"
                type="text"
                name="zoneid"
                placeholder="Zone ID"
                onChange={(e) => setZoneId(e.target.value)}
                value={zoneId}
              />
              <span className="text-danger">
                {error && "First Check your username by clicking check button"}
              </span>
              <span className="text-success">
                {playerCheck && "Username: " + playerCheck}
              </span>
            </>
          ) : (
            <input
              className="player-tag"
              type="text"
              name="playerId"
              placeholder="Player tag - #XXXXX"
              onChange={(e) => setPlayerId(e.target.value)}
              value={playerId}
            />
          )}
          {user?.block === "yes" || product?.stock === "no" ? (
            <button className="add-to-cart-btn" style={{ opacity: "0.7" }}>
              Out of Stock
            </button>
          ) : goToCart ? (
            <button
              onClick={() => navigate("/cart")}
              className="add-to-cart-btn"
            >
              Go To Cart
            </button>
          ) : (
            <button onClick={handleAddToCart} className="add-to-cart-btn">
              Add To Cart
            </button>
          )}
          <img className="safe-checkout-img" src={IMAGES.safe} alt="" />
        </div>
      </div>
      <div className="product-desc">
        <h5>Description</h5>
        <p>{product?.descTwo}</p>
      </div>
      <HowItWorks />
      {/* <SubscriberForm /> */}
    </Layout>
  );
};

export default ProductInfo;
