import React from "react";
import "./HeroSection.css";
import Slider from "react-slick";
import IMAGES from "../../img/image";
import { useNavigate } from "react-router-dom";

const HeroSection = () => {
  const navigate = useNavigate();
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="hero-slider">
      <Slider {...settings}>
        <div className="hero-img">
          <img src={IMAGES.b1} alt="" />
        </div>
        <div className="hero-img">
          <img src={IMAGES.b2} alt="" />
        </div>
        <div className="hero-img">
          <img src={IMAGES.b3} alt="" />
        </div>
      </Slider>
    </div>
  );
};

export default HeroSection;
