import React from "react";
import IMAGES from "../../img/image";
import trusted from "../../img/home/trusted.svg";
import register from "../../img/home/register.svg";
import privacy from "../../img/home/privacy.svg";
import verified from "../../img/home/verified.svg";
import "./HowItWorks.css";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import LanguageIcon from "@mui/icons-material/Language";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { useLocation } from "react-router-dom";

const HowItWorks = () => {
  const location = useLocation();
  return (
    <div className={`container-fluid how-it-works`}>
      <div className="container before-footer">
        <div className="row">
          <div className="col-sm-12 col-md-3 col-lg-3 col-12 text-center mb-2 mb-md-0 mb-lg-0">
            <LocalShippingIcon className="icon" />
            <h6 className="my-3 poppins">24/7 Instant Delivery</h6>
          </div>
          <div className="col-sm-12 col-md-3 col-lg-3 col-12 text-center mb-2 mb-md-0 mb-lg-0">
            <SyncLockIcon className="icon" />
            <h6 className="my-3 poppins">100% Safe and Legitimate</h6>
          </div>
          <div className="col-sm-12 col-md-3 col-lg-3 col-12 text-center mb-2 mb-md-0 mb-lg-0">
            <LanguageIcon className="icon" />
            <h6 className="my-3 poppins">Easy and secure payment methods</h6>
          </div>
          <div className="col-sm-12 col-md-3 col-lg-3 col-12 text-center mb-2 mb-md-0 mb-lg-0">
            <SupportAgentIcon className="icon" />
            <h6 className="my-3 poppins">24/7 Support</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
